<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12199_27)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19363 2.3803C6.16263 2.22527 6.01972 2.11857 5.86227 2.13288L3.65856 2.33322C3.57945 2.34041 3.5157 2.3755 3.47562 2.41821C3.4381 2.45819 3.42247 2.50193 3.4246 2.5496C3.54116 5.15714 4.92114 7.82312 6.94739 9.83377C8.97667 11.8474 11.5587 13.1112 13.984 13.0584C14.046 13.057 14.149 13.0041 14.1786 12.8557L14.5598 10.9499C14.5933 10.7823 14.4847 10.6193 14.3171 10.5858L12.2236 10.1671C12.1222 10.1468 12.0173 10.1786 11.9442 10.2517L11.6147 10.5812C11.1613 11.0346 10.379 11.2775 9.69623 10.8294C9.09179 10.4326 8.35167 9.78729 7.68218 9.1178C7.01269 8.44831 6.36739 7.70818 5.97061 7.10375C5.52243 6.42101 5.76536 5.6387 6.21874 5.18532L6.54825 4.85581C6.62139 4.78267 6.65314 4.67781 6.63285 4.57639L6.19363 2.3803ZM5.73156 0.695109C6.62378 0.613998 7.43359 1.21867 7.6093 2.09717L8.04851 4.29325C8.16346 4.86801 7.98356 5.46219 7.5691 5.87666L7.23959 6.20616C7.19633 6.24943 7.18184 6.28931 7.17873 6.30709C7.17834 6.30931 7.17814 6.31104 7.17805 6.31231C7.49779 6.79917 8.06568 7.4596 8.70303 8.09695C9.34038 8.7343 10.0008 9.30218 10.4877 9.62193C10.4889 9.62183 10.4907 9.62163 10.4929 9.62124C10.5107 9.61814 10.5505 9.60365 10.5938 9.56038L10.9233 9.23088C11.3378 8.81641 11.932 8.63651 12.5067 8.75146L14.6003 9.17017C15.5496 9.36004 16.1653 10.2836 15.9755 11.233L15.5943 13.1389C15.4499 13.861 14.841 14.4837 14.0154 14.5017C11.0874 14.5655 8.1478 13.0588 5.93049 10.8586C3.71016 8.65532 2.11867 5.6639 1.98234 2.61406C1.94024 1.67214 2.68399 0.972162 3.52785 0.895446L5.73156 0.695109Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_12199_27">
    <rect width="16" height="16" fill="white" transform="translate(0.5 -0.000183105)"/>
    </clipPath>
    </defs>
   </svg>

 </template>
  <script>
  export default {};
  </script>

  <style>
  </style>