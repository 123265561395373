<template>
  <div>

       <!-- Splash Screen For Deactivated account -->
       <transition name="fade" v-if="accountStatus === 'inactive' || (accountStatus !== 'inactive' && !services.length)">
          <div class="splash-screen">
            <div class="splash-content">
               <div class="icon-box">
                 <car-front-flat />
               </div>
              <h2 class="unavailable-heading">Call for Appointment</h2>
              <p class="unavailable-description">{{ accountName }} is not currently taking online bookings.</p>
              <a :href="'tel:'+accountPhoneFormated" class="call-button btn-primary">
                <call-outlined />
                <span class="text">Call {{ accountName }}</span>
              </a>
            </div>
          </div>
        </transition>
         <!-- Splash Screen For Deactivated account -->

    <transition name="fade-faster" mode="out-in" v-else >
      <select-service-substep
        v-if="displayedSubstep === 'select-service'"
        :loading="loading"
        :services="services"
        :diagnostic-service="diagnosticService"
        :selected-services="selectedServices"
        :diagnostic-appointment-feature-enabled="diagnosticAppointmentFeatureEnabled"
        @diagnostic-appt-selected="handleDiagnosticApptSelected"
        @service-selected="handleSelectService"
        @display-service-additional-notes-substep="$emit('display-service-additional-notes-substep')"
        @selected-service-confirmed="confirmSelectedService"
      />

      <diagnostic-appointment-survey
        v-else-if="displayedSubstep === 'diagnostic-appointment-survey'"
        @display-select-service-substep="handleDisplaySelectServiceSubstep"
        @header-title-change="(newTitle) => $emit('header-title-change', newTitle)"
        @display-diagnostic-additional-info-substep="
          (payload) => {
            $emit('display-diagnostic-additional-info-substep', payload);
          }
        "
      />

      <manually-describe-service-step
        key="service_additional_notes"
        :is-form-optional="true"
        v-else-if="displayedSubstep === 'service-additional-notes'"
        @info-submitted="handleServiceAdditionalNotesSubmitted"
      />

      <manually-describe-service-step
        key="diagnostic_additional_info"
        :is-form-optional="true"
        v-else-if="displayedSubstep === 'diagnostic-additional-info'"
        @info-submitted="handleDiagnosticInfoSubmitted"
      />
    </transition>
  </div>
</template>
<style lang="scss" scoped>
.btn-primary {
    background-color: #337aff;
    border-radius: 8px;
    padding: 12px 24px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    border: 1px solid #337aff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .text { margin-left: 8px;}
    &:hover {
      background-color: #226dff;
    }
  }
.splash-screen{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}
.splash-screen{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.unavailable-description{
  font-size: 14px;
  color:#2D3139;
}
.unavailable-heading{
  font-size: 16px;
  margin-bottom: 12px;
}
.icon-box{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003699;
  background: #E5EEFF;
  border-radius: 8px;
  margin:12px auto;
  width: 40px;
  height: 40px;
}
</style>
<script>
import SelectServiceSubstep from '@/components/select-service-step/SelectServiceSubstep';
import ServiceRow from '@/components/select-service-step/ServiceRow';
import ManuallyDescribeServiceStep from '@/components/select-service-step/ManuallyDescribeServiceStep';
import DiagnosticAppointmentSurvey from '@/components/select-service-step/DiagnosticAppointmentSurvey';

import ShopServicesService from '@/services/ShopServicesService';
import CarFrontFlat from '../icons/CarFrontFlat.vue';
import CallOutlined from '../icons/CallOutlined.vue';

export default {
  name: 'SelectServiceStep',
  components: {
    SelectServiceSubstep,
    ServiceRow,
    ManuallyDescribeServiceStep,
    DiagnosticAppointmentSurvey,
    CarFrontFlat,
    CallOutlined
  },
  props: {
    accountName: {
      type: String,
      required: true,
    },
    accountPhone: {
      type: String,
      required: true,
    },
    accountStatus: {
      type: String,
      required: true,
    },
    accountSlug: {
      type: String,
      required: true,
    },
    displayedSubstep: {
      type: String,
      required: true,
    },
    accountDiagnosticData: {
      type: Object,
      required: true,
    },
    diagnosticAppointmentFeatureEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      services: [
        {
          slug: 'service-1',
        },
        {
          slug: 'service-2',
        },
        {
          slug: 'service-3',
        },
      ],
      selectedServices: [],
      diagnosticService: {
        slug: 'diagnostic-appointment',
      },
    };
  },
  mounted() {
    this.fetchServices();
  },
  computed: {
    accountPhoneFormated() {
      return '+1' + this.accountPhone.replace(/[^0-9]/g, '').replace(/^\+1/g, '');
    },
  },
  methods: {
    async fetchServices() {
      const response = await ShopServicesService.get.index(this.accountSlug, {
        type: 'booking-services',
      });

      this.services = response.data.services.filter((service) => !service.is_diagnostic);
      this.diagnosticService = response.data.services.find((service) => service.is_diagnostic);

      this.loading = false;
    },
    handleDisplaySelectServiceSubstep() {
      this.selectedServices = [];
      this.$emit('display-select-service-substep');
    },
    handleDiagnosticInfoSubmitted(payload) {
      this.$emit('diagnostic-info-submitted', payload);
    },
    handleSelectService(service) {
      if (this.diagnosticServicSelected) this.diagnosticServicSelected = false;

      const isServiceAlreadySelected = this.selectedServices.find(
        (selectedService) => selectedService.slug === service.slug,
      );

      if (isServiceAlreadySelected) {
        this.selectedServices = this.selectedServices.filter(
          (selectedService) => selectedService.slug !== service.slug,
        );
        return;
      }

      this.selectedServices.push(service);
    },
    confirmSelectedService() {
      this.$emit('service-selected', { services: this.selectedServices });
    },
    handleServiceAdditionalNotesSubmitted({ description, selectedFile }) {
      this.$emit('service-selected', {
        services: this.selectedServices,
        description,
        selectedFile,
      });
    },
    handleDiagnosticApptSelected() {
      this.$emit('display-diagnostic-appointment-survey-substep', this.diagnosticService);
    },
  },
};
</script>
