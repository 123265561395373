<template>
  <svg width="24" height="22" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83239 6.99429L2.44343 8.8121C1.31222 9.24167 0.50824 10.3358 0.50824 11.6176V15.1304C0.50824 15.9589 1.17981 16.6304 2.00824 16.6304H2.00183V18.1304C2.00183 18.9589 2.6734 19.6304 3.50183 19.6304H4.29633C5.12475 19.6304 5.79633 18.9589 5.79633 18.1304V16.6304H16.3289V18.1304C16.3289 18.9589 17.0005 19.6304 17.8289 19.6304H18.4984C19.3268 19.6304 19.9984 18.9589 19.9984 18.1304V16.6304C20.8268 16.6304 21.4984 15.9589 21.4984 15.1304V11.6176C21.4984 10.3352 20.6937 9.24077 19.5618 8.81157L19.1718 6.99429H20.3799C21.0013 6.99429 21.5049 6.49061 21.5049 5.86929C21.5049 5.24797 21.0013 4.74429 20.3799 4.74429H18.6889L18.2587 2.73961C17.962 1.35682 16.7398 0.36908 15.3255 0.36908H6.67599C5.26104 0.36908 4.03845 1.35774 3.7424 2.74137L3.31383 4.74429H1.63324C1.01192 4.74429 0.50824 5.24797 0.50824 5.86929C0.50824 6.49061 1.01192 6.99429 1.63324 6.99429H2.83239ZM15.3255 2.61908H6.67599C6.32226 2.61908 6.01661 2.86625 5.94259 3.21215L4.78597 8.61764H17.2189L16.0588 3.21171C15.9846 2.86601 15.6791 2.61908 15.3255 2.61908ZM2.79633 12.6241C2.79633 11.7956 3.4679 11.1241 4.29633 11.1241C5.12475 11.1241 5.79633 11.7956 5.79633 12.6241C5.79633 13.4525 5.12475 14.1241 4.29633 14.1241C3.4679 14.1241 2.79633 13.4525 2.79633 12.6241ZM17.8289 11.1241C17.0005 11.1241 16.3289 11.7956 16.3289 12.6241C16.3289 13.4525 17.0005 14.1241 17.8289 14.1241C18.6573 14.1241 19.3289 13.4525 19.3289 12.6241C19.3289 11.7956 18.6573 11.1241 17.8289 11.1241Z" fill="#003699"/>
  </svg>
 </template>
  <script>
  export default {};
  </script>

  <style>
  </style>