const CLICK_UUID_KEY = 'sg_click_uuid';
const EXPIRATION_DAYS = 3;
import ClickTracking from '../services/ClickTracking';

export const clickTracking = {
  getClickUUID() {
    try {
      const stored = localStorage.getItem(CLICK_UUID_KEY);
      if (!stored) return null;

      const { uuid, expires, queryParams } = JSON.parse(stored);
      
      if (Date.now() > expires) {
        localStorage.removeItem(CLICK_UUID_KEY);
        return null;
      }

      return { uuid, queryParams };
    } catch (error) {
      console.error('Error getting click UUID', error);
      return null;
    }
  },

  setClickUUID(uuid, queryParams = {}) {
    if (!uuid) return;

    const trackingData = {
      uuid,
      queryParams,
      expires: Date.now() + EXPIRATION_DAYS * 24 * 60 * 60 * 1000,
    };

    localStorage.setItem(CLICK_UUID_KEY, JSON.stringify(trackingData));
  },

  shouldCreateNewClick(currentQueryParams = {}) {
    const existing = this.getClickUUID();
    if (!existing) return true;

    // Sort and compare query params to ignore order differences
    const storedParams = existing.queryParams || {};
    
    const sortedStored = Object.keys(storedParams)
      .sort()
      .reduce((acc, key) => ({
        ...acc,
        [key]: storedParams[key]
      }), {});

    const sortedCurrent = Object.keys(currentQueryParams)
      .sort()
      .reduce((acc, key) => ({
        ...acc,
        [key]: currentQueryParams[key]
      }), {});

    return JSON.stringify(sortedStored) !== JSON.stringify(sortedCurrent);
  },

  async fetchClickUUID(accountSlug = null, queryParams) {
    try {
      const clickUUID = await ClickTracking.post.clickUUID(accountSlug, queryParams);
      this.setClickUUID(clickUUID, queryParams);
    } catch (error) {
      console.error('Error fetching click UUID', error);
    }
  },
  clearClickUUID() {
    localStorage.removeItem(CLICK_UUID_KEY);
  },
};
