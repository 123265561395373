import axios from 'axios';
import apiClient from '@/client';

export default {
  post: {
    clickUUID: async (accountSlug = null, queryParams) => {
      try {
        const { data } = await apiClient.post(`/public/tracking/clicks`, {
          account_slug: accountSlug,
          referrer: document.referrer || queryParams?.referrer,
          query_params: queryParams,
        });
        return data.sg_click_uuid;
      } catch (error) {
        console.error('Error getting click UUID', error);
        return null;
      }
    },
  },
};
